<template>
    <div>
        <v-layout row wrap :align-center="!wideLayout" :justify-center="!wideLayout" :fill-height="!wideLayout">
            <v-flex xs12 md6>
                <v-card>
                    <v-card-text style="text-align:center;min-height:350px;" v-if="!chartLoading">
                        <div id="chart-container-1">
                            <fusioncharts
                            :type="chart1Type"
                            :width="width"
                            :height="height"
                            :dataFormat="dataFormat"
                            :dataSource="dataSource1"
                            >
                            </fusioncharts>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 md6>
                <v-card>
                    <v-card-text style="text-align:center;min-height:350px;" v-if="!chartLoading">
                        <div id="chart-container-2">
                            <fusioncharts
                            :type="chart2Type"
                            :width="width"
                            :height="height"
                            :dataFormat="dataFormat"
                            :dataSource="dataSource2"
                            >
                            </fusioncharts>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <div style="min-height:5px;"></div>
    </div>
</template>

<script>

export default {
    props: ['boxedLayout'],
    name: 'fusion-charts',
    data: () => ({
        interval: null,
        width: "100%",
        height: "100%",
        dataFormat: "json",

        chartLoading: true,
        //chart1
        chart1Type: "stackedbar2d",
        dataFormat1: "json",
        renderAt1: 'chart-container-1',
        dataSource1: {
            chart: {
                caption: "Tipi dovolilnic in njihov delež po statusih",
                subCaption: "",
                xAxisname: "Tip dovolilnice",
                yAxisName: "Število dovolilnic",
                numbersuffix: "",
                theme: "fusion"
            },
            categories: [],
            dataset: []
        },

        //chart2
        chart2Type: "stackedbar2d",
        dataFormat2: "json",
        renderAt2: 'chart-container-2',
        dataSource2: {
            chart: {
                caption: "Vloge in njihov delež po statusih",
                subCaption: "",
                xAxisname: "Tip dovolilnice",
                yAxisName: "Število dovolilnic",
                numbersuffix: "",
                theme: "fusion"
            },
            categories: [],
            dataset: []
        },
    }),

    computed: {
        wideLayout() {
           if(this.boxedLayout !== undefined) {
               if(this.boxedLayout === true) {
                   return false
               }

               return true
           }

           return true
       }
    },

    methods: {
        getData() {
            var vm = this;
            vm.$store.dispatch('CHART_DATA')
            .then(response => {

                //window.console.log(response.data)
                //window.console.log(response.data.chart_permits_statuses.categories)
                vm.dataSource1.categories = []
                vm.dataSource1.dataset = []
                vm.dataSource2.categories = []
                vm.dataSource2.dataset = []

                vm.dataSource1.categories.push(response.data.chart_permits_statuses.categories)
                response.data.chart_permits_statuses.dataset.forEach(item => {
                    vm.dataSource1.dataset.push(item)
                })

                vm.dataSource2.categories.push(response.data.chart_applications_statuses.categories)
                response.data.chart_applications_statuses.dataset.forEach(item => {
                    vm.dataSource2.dataset.push(item)
                })

                vm.chartLoading = false
                
            })
            .catch(error => {
                window.console.error("### ERROR FusionCharts.vue ###")
                window.console.log(error)
            })
            .finally(() => {
                //window.console.log(':)')
            })
        }
    },

    created() {

    },

    mounted() {
        var vm = this
        setTimeout(() => {
            vm.getData();
            vm.interval = setInterval(() => {
                vm.getData();
            }, 60000)
        }, 720)
    },

    beforeDestroy() {
    
    },

    destroyed() {
        clearInterval(this.interval)    
    }
}

</script>

<style scoped>
    #chart-container {
        width:100%;
        height:100%; 
    }
</style>